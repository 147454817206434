import estateL10n from '~/L10n/estate.L10n';

import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';

import { Input, Form } from 'semantic-ui-react';

import estateStore from '~/stores/estateStore';

import EditField from '~/components/Base/EditField';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';

type BusinessBlockEditingProps = HandlerEditingWrappedProps & {
    propertyType: number;
    businessPropertyType?: number | null;
    totalArea?: number | null;
    floorNumber?: number | null;
    floorsCount?: number | null;
    ceilingHeight?: number | null;
    conditionType?: number | null;
    businessEstateType?: number | null;
    hasFurniture?: boolean | null;
    hasEquipment?: boolean | null;
};

@observer
class BusinessBlockEditing extends Component<BusinessBlockEditingProps> {
    constructor(props: BusinessBlockEditingProps) {
        super(props);

        const estate = {
            propertyType: props.propertyType,
            businessPropertyType: props.businessPropertyType || null,
            totalArea: props.totalArea || null,
            floorNumber: props.floorNumber || null,
            floorsCount: props.floorsCount || null,
            ceilingHeight: props.ceilingHeight || null,
            conditionType: props.conditionType || null,
            businessEstateType: props.businessEstateType || null,
            hasFurniture: props.hasFurniture || null,
            hasEquipment: props.hasEquipment || null
        };
        estateStore.setEditingItem(props.item_id, estate);
    }

    render() {
        const { handleChange, item_id } = this.props;
        const {
            businessPropertyType,
            totalArea,
            floorNumber,
            floorsCount,
            ceilingHeight,
            conditionType,
            businessEstateType,
            hasFurniture,
            hasEquipment
        } = estateStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                <EditField.Drop
                    label="Тип недвижимости"
                    name="businessPropertyType"
                    value={businessPropertyType}
                    onChange={handleChange}
                    options={estateL10n.BUSINESS_PROPERTY_TYPE_DROPDOWN}
                />

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Площадь</label>
                        <div className="crm-Estate__fieldNowrap">
                            {[{ name: 'totalArea', value: totalArea, L10n: 'Общая' }].map(({ name, value, L10n }) => (
                                <Input
                                    required={name === 'totalArea'}
                                    step={0.1}
                                    key={`area_${name}`}
                                    type="number"
                                    min={1}
                                    max={99999}
                                    value={value || ''}
                                    size="mini"
                                    name={name}
                                    onChange={handleChange}
                                    placeholder={L10n}
                                    label={{
                                        basic: true,
                                        content: 'м²',
                                        className: 'crm-Estate__squareInput_label'
                                    }}
                                    labelPosition="right"
                                    className="crm-Estate__squareInput"
                                />
                            ))}
                        </div>
                    </Form.Field>
                </Form.Group>

                <EditField.SimpleField>
                    <Fragment>
                        <label className="crm-Estate__field_label">Этаж</label>
                        <Input
                            required
                            type="number"
                            min={-2}
                            max={255}
                            value={floorNumber || ''}
                            size="mini"
                            name="floorNumber"
                            onChange={handleChange}
                        />
                        &nbsp;из&nbsp;
                        <Input
                            required
                            type="number"
                            min={1}
                            max={255}
                            value={floorsCount || ''}
                            size="mini"
                            name="floorsCount"
                            onChange={handleChange}
                        />
                    </Fragment>
                </EditField.SimpleField>

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Высота потолков</label>
                        <Input
                            value={ceilingHeight || ''}
                            size="mini"
                            name="ceilingHeight"
                            onChange={handleChange}
                            label={{
                                basic: true,
                                content: 'см'
                            }}
                            labelPosition="right"
                            type="number"
                            min={100}
                            max={99999}
                        />
                    </Form.Field>
                </Form.Group>

                <EditField.Drop
                    label="Состояние"
                    name="conditionType"
                    value={conditionType}
                    onChange={handleChange}
                    options={estateL10n.FREE_PURPOSE_CONDITION_TYPE_DROPDOWN}
                />

                <EditField.ButtonGroup
                    label="Мебель в комнатах"
                    buttonSet={[
                        [false, 'нет'],
                        [true, 'есть']
                    ]}
                    name="hasFurniture"
                    value={hasFurniture}
                    handleChange={handleChange}
                />

                <EditField.ButtonGroup
                    label="Оборудование"
                    buttonSet={[
                        [false, 'нет'],
                        [true, 'есть']
                    ]}
                    name="hasEquipment"
                    value={hasEquipment}
                    handleChange={handleChange}
                />

                <EditField.ButtonGroup
                    label="Недвижимость"
                    name="businessEstateType"
                    value={businessEstateType}
                    handleChange={handleChange}
                    buttonSet={estateL10n.BUSINESS_ESTATE_TYPE}
                />
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, BusinessBlockEditing);
