import estateL10n from '~/L10n/estate.L10n';

import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';

import { Input, Form, Checkbox, Dropdown } from 'semantic-ui-react';

import {
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_PROPERTY_TYPE_INDUSTRY,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_SHOPPING
} from '~/types/estate.types';

import estateStore from '~/stores/estateStore';

import ButtonGroup from '~/components/Base/ButtonGroup';
import EditField from '~/components/Base/EditField';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';

import { OneFourGroup } from './PropertyBlockEditing';

type OfficeDetailsBlockEditingProps = HandlerEditingWrappedProps & {
    propertyType: number;
    taxNumber?: number | null;
    totalArea?: number | null;
    minArea?: number | null;
    floorNumber?: number | null;
    floorsCount?: number | null;
    isLegalAddressProvided?: boolean | null;
    ceilingHeight?: number | null;
    layout?: number | null;
    waterPipesCount?: number | null;
    conditionType?: number | null;
    furniturePresence?: boolean | null;
    accessType?: number | null;
    parkingType?: number | null;
    parkingPlacesCount?: number | null;
    parkingPriceMonthly?: number | null;
    parkingCurrency?: number;
    parkingIsFree?: boolean | null;
    inputType?: number | null;
    hasShopWindows?: boolean | null;
};

@observer
class OfficeDetailsBlockEditing extends Component<OfficeDetailsBlockEditingProps> {
    constructor(props: OfficeDetailsBlockEditingProps) {
        super(props);

        const estate = {
            propertyType: props.propertyType,
            taxNumber: props.taxNumber || null,
            totalArea: props.totalArea || null,
            minArea: props.minArea || null,
            floorNumber: props.floorNumber || null,
            floorsCount: props.floorsCount || null,
            isLegalAddressProvided: props.isLegalAddressProvided || null,
            ceilingHeight: props.ceilingHeight || null,
            layout: props.layout || null,
            waterPipesCount: props.waterPipesCount || null,
            conditionType: props.conditionType || null,
            furniturePresence: props.furniturePresence || null,
            accessType: props.accessType || null,
            parkingType: props.parkingType || null,
            parkingPlacesCount: props.parkingPlacesCount || null,
            parkingPriceMonthly: props.parkingPriceMonthly || null,
            parkingCurrency: props.parkingCurrency || 1,
            parkingIsFree: props.parkingIsFree || null,
            inputType: props.inputType || null,
            hasShopWindows: props.hasShopWindows || null
        };
        estateStore.setEditingItem(props.item_id, estate);
    }

    render() {
        const { handleChange, handleCheckbox, item_id } = this.props;
        const {
            propertyType,
            taxNumber,
            totalArea,
            minArea,
            floorNumber,
            floorsCount,
            isLegalAddressProvided,
            ceilingHeight,
            layout,
            waterPipesCount,
            conditionType,
            furniturePresence,
            accessType,
            parkingType,
            parkingPlacesCount,
            parkingPriceMonthly,
            parkingCurrency,
            parkingIsFree,
            inputType,
            hasShopWindows
        } = estateStore.getItem(item_id).editingItem;

        let conditionTypeOptions;

        switch (propertyType) {
            case ESTATE_PROPERTY_TYPE_SHOPPING:
                conditionTypeOptions = estateL10n.SHOPPING_CONDITION_TYPE_DROPDOWN;
                break;
            case ESTATE_PROPERTY_TYPE_FREE_PURPOSE:
                conditionTypeOptions = estateL10n.FREE_PURPOSE_CONDITION_TYPE_DROPDOWN;
                break;
            case ESTATE_PROPERTY_TYPE_INDUSTRY:
                conditionTypeOptions = estateL10n.INDUSTRY_CONDITION_TYPE_DROPDOWN;
                break;
            default:
                conditionTypeOptions = estateL10n.OFFICE_CONDITION_TYPE_DROPDOWN;
                break;
        }

        return (
            <div className="crm-Item__editingMode">
                <EditField.Drop
                    label="Состояние"
                    name="conditionType"
                    value={conditionType}
                    onChange={handleChange}
                    options={conditionTypeOptions}
                />

                <EditField.InputNumber
                    label="Номер налоговой"
                    onChange={handleChange}
                    min={1}
                    max={999999}
                    value={taxNumber}
                    name="taxNumber"
                />

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Площадь</label>
                        <div className="crm-Estate__fieldNowrap">
                            {[
                                { name: 'totalArea', value: totalArea, L10n: 'Общая' },
                                { name: 'minArea', value: minArea, L10n: 'Минимальная' }
                            ].map(({ name, value, L10n }) => (
                                <Input
                                    required={name === 'totalArea'}
                                    step={0.1}
                                    key={`area_${name}`}
                                    type="number"
                                    min={1}
                                    max={99999}
                                    value={value || ''}
                                    size="mini"
                                    name={name}
                                    onChange={handleChange}
                                    placeholder={L10n}
                                    label={{
                                        basic: true,
                                        content: 'м²',
                                        className: 'crm-Estate__squareInput_label'
                                    }}
                                    labelPosition="right"
                                    className="crm-Estate__squareInput"
                                />
                            ))}
                        </div>
                    </Form.Field>
                </Form.Group>

                <EditField.SimpleField>
                    <Fragment>
                        <label className="crm-Estate__field_label">Этаж</label>
                        <Input
                            required={true}
                            type="number"
                            min={-2}
                            max={255}
                            value={floorNumber || ''}
                            size="mini"
                            name="floorNumber"
                            onChange={handleChange}
                        />
                        &nbsp;из&nbsp;
                        <Input
                            type="number"
                            min={1}
                            max={255}
                            value={floorsCount || ''}
                            size="mini"
                            name="floorsCount"
                            onChange={handleChange}
                        />
                    </Fragment>
                </EditField.SimpleField>

                <EditField.ButtonGroup
                    label="Юридический адрес"
                    buttonSet={[
                        [false, 'нет'],
                        [true, 'есть']
                    ]}
                    name="isLegalAddressProvided"
                    value={isLegalAddressProvided}
                    handleChange={handleChange}
                />

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Высота потолков</label>
                        <Input
                            value={ceilingHeight || ''}
                            size="mini"
                            name="ceilingHeight"
                            onChange={handleChange}
                            label={{
                                basic: true,
                                content: 'см'
                            }}
                            labelPosition="right"
                            type="number"
                            min={100}
                            max={99999}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Планировка</label>
                        <ButtonGroup buttonSet={estateL10n.LAYOUTS} name="layout" value={layout} handleChange={handleChange} />
                    </Form.Field>
                </Form.Group>

                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Кол-во мокрых точек</label>
                        <OneFourGroup name="waterPipesCount" value={waterPipesCount} handleChange={handleChange} />
                    </Form.Field>
                </Form.Group>

                {[ESTATE_PROPERTY_TYPE_FREE_PURPOSE, ESTATE_PROPERTY_TYPE_SHOPPING].includes(propertyType) && (
                    <Fragment>
                        <EditField.Drop
                            label="Вход"
                            name="inputType"
                            value={inputType}
                            onChange={handleChange}
                            options={estateL10n.FREE_PURPOSE_INPUT_TYPE_DROPDOWN}
                        >
                            <Fragment>
                                &nbsp; &nbsp;
                                <Checkbox
                                    checked={hasShopWindows}
                                    name="hasShopWindows"
                                    label={<label>Витринные окна</label>}
                                    onClick={handleCheckbox}
                                />
                            </Fragment>
                        </EditField.Drop>
                    </Fragment>
                )}

                {propertyType === ESTATE_PROPERTY_TYPE_OFFICE && (
                    <Fragment>
                        <EditField.ButtonGroup
                            label="Мебель"
                            buttonSet={[
                                [false, 'нет'],
                                [true, 'есть']
                            ]}
                            name="furniturePresence"
                            value={furniturePresence}
                            handleChange={handleChange}
                        />

                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">Доступ</label>
                                <ButtonGroup
                                    buttonSet={estateL10n.OFFICE_ACCESS_TYPES}
                                    name="accessType"
                                    value={accessType}
                                    handleChange={handleChange}
                                />
                            </Form.Field>
                        </Form.Group>

                        <EditField.ButtonGroup
                            label="Парковка"
                            buttonSet={estateL10n.OFFICE_PARKING_TYPE}
                            name="parkingType"
                            value={parkingType}
                            handleChange={handleChange}
                        />

                        <EditField.InputNumber
                            label="Количество мест"
                            onChange={handleChange}
                            min={1}
                            max={999}
                            value={parkingPlacesCount}
                            name="parkingPlacesCount"
                        />

                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">Стоимость</label>
                                <Input
                                    type="number"
                                    min={0}
                                    size="mini"
                                    label={
                                        <Dropdown
                                            value={parkingCurrency}
                                            name="parkingCurrency"
                                            onChange={handleChange}
                                            options={estateL10n.CURRENCY_TYPES_DROPDOWN}
                                        />
                                    }
                                    labelPosition="right"
                                    placeholder="Цена"
                                    value={parkingPriceMonthly || ''}
                                    onChange={handleChange}
                                    name="parkingPriceMonthly"
                                    className="crm-Estate__field_rightMargin"
                                    disabled={parkingIsFree}
                                />
                                в месяц&nbsp;&nbsp;
                                <Checkbox
                                    checked={parkingIsFree}
                                    name="parkingIsFree"
                                    toggle
                                    label={<label>Бесплатная</label>}
                                    onClick={handleCheckbox}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, OfficeDetailsBlockEditing);
