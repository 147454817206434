import estateL10n from '~/L10n/estate.L10n';

import cs from 'classnames';
import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';

import { Input, Form, Checkbox, Popup } from 'semantic-ui-react';

import {
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_ROOM
} from '~/types/estate.types';

import commonStore from '~/stores/commonStore';
import estateStore from '~/stores/estateStore';

import { Switch } from '~ui/Checkbox';
import Grid from '~ui/Grid';

import ButtonGroup from '~/components/Base/ButtonGroup';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';

import RoomTypeEditing from './SubBlocks/RoomTypeEditing';
import RoomsAreas from './SubBlocks/RoomsAreas';

export const OneFourGroup = ({
    name,
    value,
    handleChange
}: {
    name: string;
    value: number | null;
    handleChange: (event: React.SyntheticEvent, Object) => void;
}) => <ButtonGroup name={name} value={value} handleChange={handleChange} buttonSet={[[0, 'нет'], [1], [2], [3], [4]]} />;

const PropertyAreaEditing = observer(
    ({
        estate_id,
        simpleMode,
        handleChange
    }: {
        estate_id: number;
        simpleMode: boolean;
        handleChange: (event: React.SyntheticEvent, Object) => void;
    }) => {
        const { propertyType, livingArea, kitchenArea, totalArea } = estateStore.getItem(estate_id).editingItem;

        let areaFields;

        if (propertyType !== ESTATE_PROPERTY_TYPE_ROOM) {
            areaFields = [
                { name: 'totalArea', value: totalArea, L10n: 'Общая', required: true },
                { name: 'livingArea', value: livingArea, L10n: 'Жилая', required: true },
                { name: 'kitchenArea', value: kitchenArea, L10n: 'Кухни', required: true }
            ];
        } else {
            areaFields = [
                { name: 'totalArea', value: totalArea, L10n: 'Всей квартиры', required: true },
                { name: 'kitchenArea', value: kitchenArea, L10n: 'Кухни (не обязательно)', required: false }
            ];
        }

        return (
            <Form.Field inline>
                <label className="crm-Estate__field_label">Площадь</label>
                <div className="crm-Estate__fieldNowrap">
                    {areaFields.map(({ name, value, L10n, required }) => (
                        <Popup
                            key={`area_${name}`}
                            inverted
                            trigger={
                                <Input
                                    required={!simpleMode && required}
                                    step={0.1}
                                    type="number"
                                    min={1}
                                    max={9999}
                                    value={value || ''}
                                    size="mini"
                                    name={name}
                                    onChange={handleChange}
                                    placeholder={L10n}
                                    label={{
                                        basic: true,
                                        content: 'м²',
                                        className: 'crm-Estate__squareInput_label'
                                    }}
                                    labelPosition="right"
                                    className="crm-Estate__squareInput"
                                />
                            }
                            content={<span>{L10n}</span>}
                        />
                    ))}
                </div>
            </Form.Field>
        );
    }
);

type PropertyBlockEditingProps = HandlerEditingWrappedProps & {
    roomsCount?: number;
    isApartments?: boolean;
    hasOwnershipRight?: boolean | null;
    isLayoutApproved?: boolean | null;
    isPenthouse?: boolean;
    floorNumber?: number | null;
    floorsCount?: number | null;
    windowsView?: number;
    totalArea?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    allRoomsArea?: string;
    loggiasCount?: number;
    balconiesCount?: number;
    separateWcsCount?: number;
    combinedWcsCount?: number;
    repairType?: number | null;
    decoration?: number | null;
    hasPhone?: boolean | null;
    shareAmount?: string | null;
    roomsForSaleCount?: number | null;
    roomType?: number | null;
    propertyType?: number | null;

    simpleMode?: boolean;
};

@observer
class PropertyBlockEditing extends Component<PropertyBlockEditingProps> {
    constructor(props: PropertyBlockEditingProps) {
        super(props);

        const { propertyType } = props;

        const estate = {
            estate_id: props.item_id,

            roomsCount: props.roomsCount || 1,
            isApartments: props.isApartments || false,
            hasOwnershipRight: props.hasOwnershipRight || null,
            isLayoutApproved: props.isLayoutApproved || null,
            isPenthouse: props.isPenthouse || false,
            floorNumber: props.floorNumber || null,
            floorsCount: props.floorsCount || null,
            windowsView: props.windowsView || 0,
            totalArea: props.totalArea || null,
            livingArea: props.livingArea || null,
            kitchenArea: props.kitchenArea || null,
            allRoomsArea: props.allRoomsArea || '',
            loggiasCount: props.loggiasCount || 0,
            balconiesCount: props.balconiesCount || 0,
            separateWcsCount: props.separateWcsCount || 0,
            combinedWcsCount: props.combinedWcsCount || 0,
            repairType: typeof props.repairType === 'number' ? props.repairType : null,
            decoration: typeof props.decoration === 'number' ? props.decoration : null,
            hasPhone: typeof props.hasPhone === 'boolean' ? props.hasPhone : null,

            roomsForSaleCount: props.roomsForSaleCount || (propertyType === ESTATE_PROPERTY_TYPE_ROOM ? 1 : null),
            shareAmount: props.shareAmount || null,
            roomType: props.roomType || null,

            propertyType: propertyType || 0
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    }

    handleWindowView = (event: React.SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => {
        event.preventDefault();

        const { item_id } = this.props;

        let { windowsView } = estateStore.getItem(item_id).editingItem;

        const weight = name === 'courtView' ? 1 : 2;
        windowsView += (checked ? 1 : -1) * weight;

        estateStore.setEditingItem(item_id, {
            windowsView
        });
    };

    handleRoomType = (roomType: number) => {
        estateStore.setEditingItem(this.props.item_id, {
            roomType
        });
    };

    handleRoomsAreaChange = (allRoomsArea: string) => {
        estateStore.setEditingItem(this.props.item_id, {
            allRoomsArea
        });
    };

    render() {
        const { simpleMode, item_id, editing, handleChange, handleCheckbox } = this.props;
        const {
            roomsCount,
            isApartments,
            hasOwnershipRight,
            isLayoutApproved,
            isPenthouse,
            floorNumber,
            floorsCount,
            windowsView,
            livingArea,
            kitchenArea,
            totalArea,
            allRoomsArea,
            loggiasCount,
            balconiesCount,
            separateWcsCount,
            combinedWcsCount,
            repairType,
            hasPhone,
            decoration,

            propertyType,
            roomsForSaleCount,
            shareAmount,
            roomType
        } = estateStore.getItem(item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                {propertyType === ESTATE_PROPERTY_TYPE_ROOM && (
                    <>
                        <Form.Group>
                            <Form.Field inline className="crm-Estate__roomsCount">
                                <label className="crm-Estate__field_label">Комнат на продажу</label>
                                <div style={{ display: 'inline-block' }}>
                                    <ButtonGroup
                                        buttonSet={[[1], [2], [3], [4], [5]]}
                                        name="roomsForSaleCount"
                                        value={roomsForSaleCount}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </Form.Field>
                        </Form.Group>
                        <RoomTypeEditing onChange={this.handleRoomType} roomType={roomType} roomsCount={roomsForSaleCount} />
                    </>
                )}
                <Form.Group>
                    <Form.Field inline className="crm-Estate__roomsCount">
                        <label className={cs({ 'crm-Estate__field_label': !editing, 'crm-Estate__field_labelRooms': editing })}>
                            Комнат
                        </label>
                        <div style={{ display: 'inline-block' }}>
                            <ButtonGroup
                                buttonSet={estateL10n.ROOMS_COUNT}
                                name="roomsCount"
                                value={roomsCount}
                                handleChange={handleChange}
                            />
                            {!simpleMode && (
                                <div style={{ marginTop: '10px' }}>
                                    <Switch label="Апартаменты" name="isApartments" checked={isApartments} onChange={handleCheckbox} />
                                    <Switch label="Пентхаус" name="isPenthouse" checked={isPenthouse} onChange={handleCheckbox} />
                                </div>
                            )}
                        </div>
                    </Form.Field>
                </Form.Group>
                {propertyType === ESTATE_PROPERTY_TYPE_PART && (
                    <Form.Group>
                        <Grid spacing={1}>
                            <Grid.Column width={12}>
                                <Form.Field inline>
                                    <label className="crm-Estate__field_label">Доля</label>
                                    <Input
                                        value={shareAmount || ''}
                                        size="mini"
                                        placeholder="В свободной форме"
                                        name="shareAmount"
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={commonStore.isMobile ? 12 : 6}>
                                <Switch
                                    label="Есть ли право собственности"
                                    name="hasOwnershipRight"
                                    checked={hasOwnershipRight}
                                    onChange={handleCheckbox}
                                />
                            </Grid.Column>
                            <Grid.Column width={commonStore.isMobile ? 12 : 6}>
                                <Switch
                                    label="Узаконена ли планировка"
                                    name="isLayoutApproved"
                                    checked={isLayoutApproved}
                                    onChange={handleCheckbox}
                                />
                            </Grid.Column>
                        </Grid>
                    </Form.Group>
                )}
                <Form.Group>
                    <PropertyAreaEditing estate_id={item_id} simpleMode={simpleMode} handleChange={handleChange} />
                </Form.Group>
                {!simpleMode && (
                    <>
                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">Площадь комнат</label>
                                <RoomsAreas
                                    roomsCount={roomsForSaleCount || (roomsCount > 6 ? 1 : roomsCount) || 1}
                                    allRoomsArea={String(allRoomsArea)}
                                    onChange={this.handleRoomsAreaChange}
                                    propertyType={propertyType}
                                />
                            </Form.Field>
                        </Form.Group>
                        {[ESTATE_PROPERTY_TYPE_FLAT, ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(
                            propertyType
                        ) && <RoomTypeEditing roomType={roomType} roomsCount={roomsCount} onChange={this.handleRoomType} />}
                    </>
                )}
                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Этаж</label>
                        <Input
                            required={!simpleMode}
                            type="number"
                            min={1}
                            max={255}
                            value={floorNumber || ''}
                            size="mini"
                            name="floorNumber"
                            onChange={handleChange}
                        />
                        &nbsp;из&nbsp;
                        <Input
                            type="number"
                            required={!simpleMode}
                            min={1}
                            max={255}
                            value={floorsCount || ''}
                            size="mini"
                            name="floorsCount"
                            onChange={handleChange}
                        />
                    </Form.Field>
                </Form.Group>
                {!simpleMode && (
                    <Fragment>
                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">Лоджия</label>
                                <OneFourGroup name="loggiasCount" value={loggiasCount} handleChange={handleChange} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">Балкон</label>
                                <OneFourGroup name="balconiesCount" value={balconiesCount} handleChange={handleChange} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">Вид из окна</label>
                                <div className="crm-Estate__fieldNowrap">
                                    <Checkbox
                                        checked={windowsView === 1 || windowsView === 3}
                                        name="courtView"
                                        label={<label>Во двор</label>}
                                        onClick={this.handleWindowView}
                                        className="crm-Estate__field_rightMargin"
                                    />
                                    <Checkbox
                                        checked={windowsView > 1}
                                        name="streetView"
                                        label={<label>На улицу</label>}
                                        onClick={this.handleWindowView}
                                    />
                                </div>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">Раздельные санузлы</label>
                                <OneFourGroup name="separateWcsCount" value={separateWcsCount} handleChange={handleChange} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">Совмещен.санузлы</label>
                                <OneFourGroup name="combinedWcsCount" value={combinedWcsCount} handleChange={handleChange} />
                            </Form.Field>
                        </Form.Group>
                        {![ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(propertyType) && (
                            <Form.Group>
                                <Form.Field inline>
                                    <label className="crm-Estate__field_label">Ремонт</label>
                                    <ButtonGroup
                                        buttonSet={estateL10n.REPAIR_TYPES}
                                        name="repairType"
                                        value={repairType}
                                        handleChange={handleChange}
                                    />
                                </Form.Field>
                            </Form.Group>
                        )}
                        {[ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(propertyType) && (
                            <Form.Group>
                                <Form.Field inline>
                                    <label className="crm-Estate__field_label">Отделка</label>
                                    <ButtonGroup
                                        buttonSet={estateL10n.DECORATION_TYPES}
                                        name="decoration"
                                        value={decoration}
                                        handleChange={handleChange}
                                    />
                                </Form.Field>
                            </Form.Group>
                        )}
                        <Form.Group>
                            <Form.Field inline>
                                <label className="crm-Estate__field_label">Телефон</label>
                                <ButtonGroup
                                    buttonSet={[
                                        [false, 'нет'],
                                        [true, 'есть']
                                    ]}
                                    name="hasPhone"
                                    value={hasPhone}
                                    handleChange={handleChange}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, PropertyBlockEditing);
