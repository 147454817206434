import estateL10n from '~/L10n/estate.L10n';

import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';

import { Card, Grid, Header } from 'semantic-ui-react';

import {
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_ROOM
} from '~/types/estate.types';

import { nl2br } from '~/api/commentsApi';

import commonStore from '~/stores/commonStore';
import estateStore from '~/stores/estateStore';

import EditingBlockWrapper from '~/components/Items/EditingBlockWrapper';
import { EditingWrappedProps } from '~/components/Items/EditingBlockWrapper';
import DescriptionBlockEditing from '~/components/Items/Estate/EditBlocks/DescriptionBlockEditing';

import PropertyBlockEditing from '../EditBlocks/PropertyBlockEditing';

@observer
class PropertyBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        const {
            hasPhone,
            repairType,
            windowsView,
            loggiasCount,
            balconiesCount,
            separateWcsCount,
            combinedWcsCount,
            roomsCount,
            description,
            allRoomsArea,
            kitchenArea,
            livingArea,
            totalArea,
            floorNumber,
            floorsCount,

            propertyType,
            shareAmount,
            hasOwnershipRight,
            isLayoutApproved,
            roomsForSaleCount,
            roomType,
            decoration,

            isApartments,
            isPenthouse,
            buildYear
        } = item || {};

        const tooManyRooms = Boolean(roomsCount && roomsCount > 2);
        const { isMobile } = commonStore;

        return (
            <Card fluid color="blue">
                {!editing && (
                    <Fragment>
                        <Card.Content>
                            <Grid columns={livingArea ? 5 : 4}>
                                <Grid.Row textAlign="center">
                                    <Grid.Column>
                                        <div className="crm-Estate__about_title">Общая</div>
                                        <span className="crm-Estate__about_value">{totalArea} м²</span>
                                    </Grid.Column>
                                    {Number(livingArea) > 0 && (
                                        <Grid.Column>
                                            <div className="crm-Estate__about_title">Жилая</div>
                                            <span className="crm-Estate__about_value">{livingArea} м²</span>
                                        </Grid.Column>
                                    )}
                                    <Grid.Column>
                                        <div className="crm-Estate__about_title">Кухни</div>
                                        <span className="crm-Estate__about_value">{kitchenArea || '-'} м²</span>
                                    </Grid.Column>
                                    {!tooManyRooms && !isMobile && (
                                        <Grid.Column>
                                            <div className="crm-Estate__about_title">Комнат</div>
                                            <span className="crm-Estate__about_value">{allRoomsArea} м²</span>
                                        </Grid.Column>
                                    )}
                                    <Grid.Column>
                                        <div className="crm-Estate__about_title">Этаж</div>
                                        <span className="crm-Estate__about_value">
                                            {floorNumber} из {floorsCount}
                                        </span>
                                    </Grid.Column>
                                    {tooManyRooms && !isMobile && (
                                        <Grid.Column>
                                            <div className="crm-Estate__about_title">Год</div>
                                            <span className="crm-Estate__about_value">{buildYear}</span>
                                        </Grid.Column>
                                    )}
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                        <Card.Content>
                            <span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />
                        </Card.Content>
                        <Card.Content>
                            <Header size="tiny">Квартира</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    {(isApartments || isPenthouse) && (
                                        <Fragment>
                                            <Grid.Column>Статус</Grid.Column>
                                            <Grid.Column>
                                                {isApartments && 'Апартаменты'} {isPenthouse && 'Пентхаус'}
                                            </Grid.Column>
                                        </Fragment>
                                    )}
                                    {propertyType === ESTATE_PROPERTY_TYPE_PART && (
                                        <Fragment>
                                            <Grid.Column>Доля</Grid.Column>
                                            <Grid.Column>
                                                {shareAmount}
                                                {hasOwnershipRight || isLayoutApproved
                                                    ? ` (${hasOwnershipRight ? 'Есть право собственности' : ''}${
                                                          hasOwnershipRight && isLayoutApproved ? '; ' : ''
                                                      }${isLayoutApproved ? 'Узаконена планировка' : ''})`
                                                    : ''}
                                            </Grid.Column>
                                        </Fragment>
                                    )}
                                    {propertyType === ESTATE_PROPERTY_TYPE_ROOM && (
                                        <Fragment>
                                            <Grid.Column>Комнат на продажу</Grid.Column>
                                            <Grid.Column>
                                                {roomsForSaleCount}
                                                {(roomType === 1 || roomType === 3) && ' смежная'}
                                                {roomType && roomType === 3 && ' и'}
                                                {roomType && roomType > 1 && ' изолированная'}
                                            </Grid.Column>
                                        </Fragment>
                                    )}
                                    <Grid.Column>Количество комнат</Grid.Column>
                                    <Grid.Column>{estateL10n.ROOMS_COUNT_FIND(roomsCount)}</Grid.Column>
                                    {tooManyRooms && (
                                        <Fragment>
                                            <Grid.Column>Площадь комнат</Grid.Column>
                                            <Grid.Column>{allRoomsArea || '-'} м²</Grid.Column>
                                        </Fragment>
                                    )}

                                    {![ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(
                                        propertyType
                                    ) && (
                                        <Fragment>
                                            <Grid.Column>Ремонт</Grid.Column>
                                            <Grid.Column>{estateL10n.REPAIR_TYPES_FIND(repairType)}</Grid.Column>
                                        </Fragment>
                                    )}

                                    {[ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(
                                        propertyType
                                    ) && (
                                        <Fragment>
                                            <Grid.Column>Отделка</Grid.Column>
                                            <Grid.Column>{estateL10n.DECORATION_TYPES_FIND(decoration)}</Grid.Column>
                                        </Fragment>
                                    )}

                                    <Grid.Column>Санузел</Grid.Column>
                                    <Grid.Column>
                                        {separateWcsCount === 1 && '1 раздельный '}
                                        {separateWcsCount > 1 && `${separateWcsCount} раздельных `}
                                        {combinedWcsCount === 1 && '1 совместный '}
                                        {combinedWcsCount > 1 && `${combinedWcsCount} совместных `}
                                    </Grid.Column>
                                    <Grid.Column>Балкон</Grid.Column>
                                    <Grid.Column>
                                        {balconiesCount === 0 && loggiasCount === 0 && 'нет'}
                                        {balconiesCount === 1 && '1 балкон '}
                                        {balconiesCount > 1 && `${balconiesCount} балкона `}
                                        {loggiasCount === 1 && '1 лоджия '}
                                        {loggiasCount > 1 && `${loggiasCount} лоджии `}
                                    </Grid.Column>
                                    <Grid.Column>Вид из окон</Grid.Column>
                                    <Grid.Column>
                                        {(windowsView === 1 || windowsView === 3) && 'во двор'}
                                        {windowsView === 3 && ' и '}
                                        {windowsView > 1 && 'на улицу'}
                                    </Grid.Column>
                                    <Grid.Column>Телефон</Grid.Column>
                                    <Grid.Column>{typeof hasPhone === 'boolean' && (hasPhone ? 'есть' : 'нет')}</Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                    </Fragment>
                )}
                {editing && (
                    <Card.Content>
                        <PropertyBlockEditing editing item_id={item_id} {...item} />
                        <DescriptionBlockEditing editing item_id={item_id} description={item.description} />
                    </Card.Content>
                )}
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, PropertyBlockShow);
