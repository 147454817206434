import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';

import { Form } from 'semantic-ui-react';

import { checkFormValid } from '~/common/forms';

import authStore from '~/stores/authStore';
import commonStore from '~/stores/commonStore';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import userStore from '~/stores/userStore';

import AccountTreeIcon from '@material-ui/icons/AccountTree';

import ToggleButton from '@material-ui/lab/ToggleButton';

import { StyledToggleButtonGroup } from '~ui/FilterPult';
import { Modal, ModalBtnAction, ModalContent } from '~ui/Modal';
import Progress from '~ui/Progress';

import EditField from '../Base/EditField';
import { HandleChangeType } from '../Items/HandlerEditingBlockWrapper';
import UserDropdown from '../ItemsDropdowns/UserDropdown';
import ListError from '../ListError';

const GroupForm = observer(
    ({
        parent_id,
        name,
        chief_id,
        region_id,
        onChangeField,
        handleChangeUser
    }: {
        parent_id: number;
        name: string;
        chief_id: number | null;
        region_id: number | null;
        onChangeField: HandleChangeType;
        handleChangeUser: (chief_id: number | Array<number>) => void;
    }) => {
        const { getOfficesDropdown } = userStore;
        const { loadingRegions, regionsDropdown } = exportingBaseStore;

        return (
            <div className="crm-Item__editingMode crm-Triggers__modalField">
                {parent_id === CREATING_ITEM_ID && (
                    <EditField.Drop
                        loading={loadingRegions}
                        label="Регион"
                        name="region_id"
                        value={region_id}
                        onChange={onChangeField}
                        options={regionsDropdown}
                    />
                )}
                {parent_id > CREATING_ITEM_ID && (
                    <EditField.Drop label="Офис" name="parent_id" value={parent_id} onChange={onChangeField} options={getOfficesDropdown} />
                )}

                <EditField.Text
                    label={parent_id ? 'Название отдела' : 'Название Офиса'}
                    placeholder="Название"
                    value={name}
                    name="name"
                    onChange={onChangeField}
                    required=".{3,}"
                />

                <Form.Group>
                    <Form.Field inline size="tiny">
                        <label className="crm-Estate__field_label">Руководитель {parent_id ? 'отдела' : 'Офиса'}</label>
                        <UserDropdown user_id={chief_id} onChange={handleChangeUser} />
                    </Form.Field>
                </Form.Group>
            </div>
        );
    }
);

type GroupModalProps = {
    group_id: number;
    onClose: () => void;
};

type GroupModalState = {
    name: string;
    parent_id: number;
    chief_id?: number;
    region_id: number | null;

    loading: boolean;
    errors: [];
    creatingType: 1 | 2;
};

@observer
class GroupModal extends Component<GroupModalProps, GroupModalState> {
    $form: HTMLFormElement;

    constructor(props: GroupModalProps) {
        super(props);

        const { group_id } = this.props;

        if (group_id !== CREATING_ITEM_ID) {
            const { name, parent_id, chief, region_id } = userStore.findGroupById(group_id) || {};

            this.state = {
                name,
                parent_id,
                region_id,
                chief_id: chief ? chief.user_id : null,
                loading: false,
                errors: [],
                creatingType: 1
            };
        } else {
            this.state = {
                name: '',
                parent_id: 0,
                region_id: null,
                chief_id: null,
                loading: false,
                errors: [],
                creatingType: 1
            };
        }
    }

    handleChangeField: HandleChangeType = (event, { type, name, value }) => {
        event.preventDefault();
        if (name === 'name') {
            this.setState({ [name]: value });
        } else if (name === 'parent_id') {
            this.setState({ [name]: value });
        } else if (name === 'region_id') {
            this.setState({ [name]: value });
        }
    };

    handleChangeUser = (chief_id: number | Array<number>) => {
        if (typeof chief_id === 'number') {
            this.setState({ chief_id });
        }
    };

    handleSaveGroup = async (event: React.SyntheticEvent) => {
        const { name, parent_id, chief_id, region_id } = this.state;

        if (checkFormValid(this.$form, true)) {
            this.setState({ loading: true, errors: [] });

            event.preventDefault();
            event.stopPropagation();

            try {
                await userStore.editGroup(this.props.group_id, { name, parent_id, chief_id, region_id });
                this.props.onClose();
            } catch (errors) {
                this.setState({ loading: false, errors });
            }
        }
    };

    handleDelete = async () => {
        this.setState({ loading: true, errors: [] });
        try {
            await userStore.editGroup(this.props.group_id, { enable: false });
            this.props.onClose();
        } catch (errors) {
            this.setState({ loading: false, errors });
        }
    };

    handleChangeCreatingType = (event, creatingType) => {
        // Берем самый первый офис, если переключаемся на отдел
        const parent_id = creatingType === 2 ? Number(userStore.getOfficesDropdown[0].value) : 0;
        this.setState({ creatingType, parent_id });
    };

    render() {
        const { group_id, onClose } = this.props;
        const { name, parent_id, chief_id, loading, errors, creatingType, region_id } = this.state;
        const { loadingGroups } = userStore;

        const modalActions: ModalBtnAction[] = [{ onClick: onClose, label: 'Отмена', variant: 'text', color: 'primary' }];
        if (group_id !== CREATING_ITEM_ID && authStore.canDelete('groups', [])) {
            modalActions.push({
                onClick: this.handleDelete,
                label: 'Удалить',
                color: 'secondary',
                variant: 'text'
            });
        }
        modalActions.push({
            onClick: this.handleSaveGroup,
            label: group_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
            color: 'primary',
            variant: 'outlined',
            disabled: loading || !name || region_id === 0
        });

        return (
            <Modal
                disableBackdropClick={loading}
                fullScreen={commonStore.isMobile}
                onClose={onClose}
                maxWidth="sm"
                header={
                    <Fragment>
                        <AccountTreeIcon /> &nbsp;
                        {group_id > CREATING_ITEM_ID && (parent_id ? 'Отдел' : 'Офис')}
                        {group_id === CREATING_ITEM_ID && (
                            <>
                                <StyledToggleButtonGroup
                                    exclusive
                                    size="small"
                                    value={creatingType}
                                    onChange={this.handleChangeCreatingType}
                                >
                                    <ToggleButton disabled={loadingGroups} value={1}>
                                        Офис
                                    </ToggleButton>
                                    <ToggleButton disabled={loadingGroups} value={2}>
                                        Отдел
                                    </ToggleButton>
                                </StyledToggleButtonGroup>
                            </>
                        )}
                    </Fragment>
                }
                actions={modalActions}
                style={{ overflowY: 'visible' }}
            >
                <ModalContent style={{ overflowY: 'visible' }}>
                    <ListError errors={Array.from(errors)} />

                    <form ref={$el => (this.$form = $el)}>
                        <GroupForm
                            name={name}
                            parent_id={parent_id}
                            chief_id={chief_id}
                            region_id={region_id}
                            onChangeField={this.handleChangeField}
                            handleChangeUser={this.handleChangeUser}
                        />
                    </form>

                    <Progress show={loading} />
                </ModalContent>
            </Modal>
        );
    }
}

export default GroupModal;
