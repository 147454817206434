import React, { PureComponent, Fragment, SyntheticEvent } from 'react';
import { ReactNode } from 'react';

import { Input, Form, Checkbox, Dropdown, SemanticSIZES } from 'semantic-ui-react';

import { DropdownType } from '~/stores/prototypes/ListStore.prototype';

import { ButtonGroupType } from './ButtonGroup';
import ButtonGroupBase from './ButtonGroup';
import SmartTextArea from './SmartTextArea';

export type HandleChangeType = (
    event: SyntheticEvent,
    data: { type?: string; name: string; value: any; array?: string; index?: number }
) => void;

type EditFieldProps = {
    label: string | ReactNode;
    placeholder?: string;
    value: any;
    name: string;
    onChange: HandleChangeType;
    required?: boolean | string;
    children?: ReactNode;
    size?: SemanticSIZES;
    fluid?: boolean;
};

type InputNumberProps = EditFieldProps & {
    min?: number;
    max?: number;
    step?: number;
    extraLabel?: string | ReactNode;
};

export class InputNumber extends PureComponent<InputNumberProps> {
    render() {
        const { label, onChange, min, max, value, name, step, extraLabel, children, required } = this.props;

        return (
            <Form.Group>
                <Form.Field inline>
                    <label className="crm-Estate__field_label">{label}</label>
                    <div className="crm-Estate__fieldNowrap">
                        <Input
                            type="number"
                            placeholder={label}
                            size="mini"
                            value={value || ''}
                            name={name}
                            onChange={onChange}
                            min={min}
                            max={max}
                            step={step}
                            label={
                                typeof extraLabel === 'string'
                                    ? {
                                          basic: true,
                                          content: extraLabel
                                      }
                                    : extraLabel
                            }
                            labelPosition={extraLabel ? 'right' : null}
                            required={Boolean(required)}
                        />
                    </div>
                    {children}
                </Form.Field>
            </Form.Group>
        );
    }
}

export class Text extends PureComponent<EditFieldProps> {
    render() {
        const { label, onChange, value, name, placeholder, required, size, children, fluid } = this.props;

        return (
            <Form.Group>
                <Form.Field inline className={fluid ? 'crm-Item__field_fluid' : ''}>
                    <label className="crm-Estate__field_label">{label}</label>
                    <div className={`crm-Estate__fieldNowrap ${fluid ? 'crm-Item__field_fluid' : ''}`}>
                        <Input
                            placeholder={placeholder || label}
                            required={Boolean(required)}
                            pattern={typeof required === 'string' ? required : undefined}
                            size={!size || size === 'tiny' || size === 'medium' ? 'mini' : size}
                            value={value || ''}
                            name={name}
                            onChange={onChange}
                            fluid={fluid}
                        />
                    </div>
                    {children}
                </Form.Field>
            </Form.Group>
        );
    }
}

export class SimpleField extends PureComponent<{ children: ReactNode }> {
    render() {
        return (
            <Form.Group>
                <Form.Field inline>{this.props.children}</Form.Field>
            </Form.Group>
        );
    }
}

export class LabeledField extends PureComponent<{ children: ReactNode; label: string }> {
    render() {
        return (
            <Form.Group>
                <Form.Field inline>
                    <label className="crm-Estate__field_label">{this.props.label}</label>
                    {this.props.children}
                </Form.Field>
            </Form.Group>
        );
    }
}

type ButtonGroupProps = ButtonGroupType & { label: string; children?: ReactNode };

export class ButtonGroup extends PureComponent<ButtonGroupProps> {
    render() {
        const { label, value, name, size, children } = this.props;

        if (!('handleChange' in this.props)) {
            return null;
        }

        const props: ButtonGroupType = {
            handleChange: this.props.handleChange,
            value,
            name,
            size,
            ...('buttonSet' in this.props ? { buttonSet: this.props.buttonSet } : { options: this.props.options })
        };

        return (
            <Form.Group>
                <Form.Field inline>
                    <label className="crm-Estate__field_label">{label}</label>
                    <ButtonGroupBase {...props} />
                    {children}
                </Form.Field>
            </Form.Group>
        );
    }
}

export class Drop extends PureComponent<
    EditFieldProps & {
        options: Array<DropdownType>;
        search?: boolean;
        loading?: boolean;
        multiple?: boolean;
        clearable?: boolean;
        className?: string;
    }
> {
    render() {
        const { label, onChange, value, name, options, search, loading, multiple, clearable, size, className } = this.props;

        return (
            <Form.Group>
                <Form.Field inline size="tiny" className={className}>
                    <label className="crm-Estate__field_label">{label}</label>
                    <span className={`ui form ${size}`}>
                        <Dropdown
                            selection
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeholder={typeof label === 'string' ? label : null}
                            options={options}
                            search={search}
                            loading={loading}
                            multiple={multiple}
                            clearable={clearable}
                            type={clearable ? 'clearable' : null}
                        />
                    </span>
                    {this.props.children}
                </Form.Field>
            </Form.Group>
        );
    }
}

export class Area extends PureComponent<{
    label: ReactNode;
    value: string | null;
    name: string;
    onChange: (data: { name: string; value: string }) => void;
    style?: React.CSSProperties;
}> {
    render() {
        const { label, value, name, style, onChange } = this.props;

        return (
            <Form.Group>
                <Form.Field inline style={{ width: '100%' }}>
                    <label className="crm-Estate__field_label" style={{ verticalAlign: 'top', ...(style || {}) }}>
                        {label}
                    </label>

                    <SmartTextArea value={value || ''} name={name} onChange={onChange} />
                </Form.Field>
            </Form.Group>
        );
    }
}

export default {
    InputNumber,
    SimpleField,
    LabeledField,
    ButtonGroup,
    Text,
    Drop,
    Area
};
