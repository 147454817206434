import React, { useState } from 'react';

import { observer } from 'mobx-react';

import * as estateApi from '~/api/estateApi';

import deepCopy from '~/common/deepCopy';
import useConstructor from '~/common/useConstructor';

import estateStore from '~/stores/estateStore';

import FlashOnIcon from '@material-ui/icons/FlashOn';

import Box from '@material-ui/core/Box';

import Button from '~ui/Button';

import EditField from '~/components/Base/EditField';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import ListError from '~/components/ListError';

type DescriptionBlockEditingProps = HandlerEditingWrappedProps & {
    description?: string;
};

const DescriptionBlockEditing = (props: DescriptionBlockEditingProps) => {
    useConstructor(() => {
        const estate = {
            estate_id: props.item_id,
            description: props.description || ''
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    });

    const { description } = estateStore.getItem(props.item_id).editingItem;

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [done, setDone] = useState(false);

    const handleComputeAiDescription = async (): Promise<void> => {
        const estateDraft = deepCopy(estateStore.getItem(props.item_id).editingItem);

        setLoading(true);
        setErrors([]);
        try {
            const message = await estateApi.computeAiEstateDescription(estateDraft);
            estateStore.setEditingItem(props.item_id, { description: message });
            setDone(true);
        } catch (errors) {
            setErrors(errors);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="crm-Item__editingMode">
            {errors.length > 0 && <ListError errors={errors} />}
            <EditField.Area
                name="description"
                style={{ minWidth: '100%' }}
                label={
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <span>Описание</span>
                        <Button
                            size="small"
                            disabled={done}
                            loading={loading}
                            onClick={handleComputeAiDescription}
                            icon={<FlashOnIcon />}
                            color="secondary"
                            tooltip="Сгенерировать описание через ИИ"
                        ></Button>
                    </Box>
                }
                value={description}
                onChange={props.handleTextAreaChange}
            />
        </div>
    );
};

export default HandlerEditingBlockWrapper(estateStore, observer(DescriptionBlockEditing));
