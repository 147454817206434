import estateL10n from '~/L10n/estate.L10n';

import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';

import { Card, Grid, Header } from 'semantic-ui-react';

import { nl2br } from '~/api/commentsApi';

import estateStore from '~/stores/estateStore';

import EditingBlockWrapper from '~/components/Items/EditingBlockWrapper';
import { EditingWrappedProps } from '~/components/Items/EditingBlockWrapper';
import DescriptionBlockEditing from '~/components/Items/Estate/EditBlocks/DescriptionBlockEditing';

import BusinessBlockEditing from '../EditBlocks/BusinessBlockEditing';

@observer
class BusinessBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        const {
            businessPropertyType,
            totalArea,
            floorNumber,
            floorsCount,
            ceilingHeight,
            conditionType,
            description,
            businessEstateType,
            hasFurniture,
            hasEquipment
        } = item || {};

        return (
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Card.Content>
                                <Grid>
                                    <Grid.Row textAlign="center">
                                        <Grid.Column width={4}>
                                            <div className="crm-Estate__about_title">Площадь</div>
                                            <span className="crm-Estate__about_value">{totalArea} м²</span>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <div className="crm-Estate__about_title">Этаж</div>
                                            <span className="crm-Estate__about_value">
                                                {floorNumber} из {floorsCount}
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <div className="crm-Estate__about_title">Тип недвижимости</div>
                                            <span className="crm-Estate__about_value">
                                                {estateL10n.BUSINESS_PROPERTY_TYPE_FIND(businessPropertyType)}
                                            </span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Card.Content>
                            <Card.Content>
                                <div
                                    style={{ padding: '15px 0' }}
                                    dangerouslySetInnerHTML={{ __html: description && nl2br(description) }}
                                />
                            </Card.Content>
                            <Card.Content>
                                <Header size="tiny">Помещение</Header>
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column>Высота потолков</Grid.Column>
                                        <Grid.Column>{ceilingHeight} см</Grid.Column>

                                        <Grid.Column>Состояние</Grid.Column>
                                        <Grid.Column>{estateL10n.FREE_PURPOSE_CONDITION_TYPE_FIND(conditionType)}</Grid.Column>

                                        <Grid.Column>Мебель в комнатах</Grid.Column>
                                        <Grid.Column>
                                            {typeof hasFurniture === 'boolean' ? (hasFurniture ? 'есть' : 'нет') : 'н/д'}
                                        </Grid.Column>

                                        <Grid.Column>Оборудование</Grid.Column>
                                        <Grid.Column>
                                            {typeof hasEquipment === 'boolean' ? (hasEquipment ? 'есть' : 'нет') : 'н/д'}
                                        </Grid.Column>

                                        <Grid.Column>Недвижимость</Grid.Column>
                                        <Grid.Column>{estateL10n.BUSINESS_ESTATE_TYPE_FIND(businessEstateType)}</Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Card.Content>
                        </Fragment>
                    )}
                    {editing && (
                        <>
                            <BusinessBlockEditing item_id={item_id} {...item} />
                            <DescriptionBlockEditing editing item_id={item_id} description={item.description} />
                        </>
                    )}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, BusinessBlockShow);
