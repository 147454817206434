import estateL10n from '~/L10n/estate.L10n';

import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';

import { Card, Grid, Header } from 'semantic-ui-react';

import { nl2br } from '~/api/commentsApi';

import estateStore from '~/stores/estateStore';

import ShadowFragment from '~/components/Base/ShadowFragment';
import EditingBlockWrapper from '~/components/Items/EditingBlockWrapper';
import { EditingWrappedProps } from '~/components/Items/EditingBlockWrapper';
import DescriptionBlockEditing from '~/components/Items/Estate/EditBlocks/DescriptionBlockEditing';

import LandBlockEditing, { LAND_INFRASTURCTURE_FIELDS } from '../EditBlocks/LandBlockEditing';
import { printLandAreaUnitType } from './EstateInfoBlock';

@observer
class LandBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        if (!item) {
            return null;
        }
        const { settlementName, landArea, landAreaUnitType, landStatus, description, highway_id, distanceFromMKAD } = item;

        const { highwaysListLoading } = estateStore;

        const highway = !highwaysListLoading && estateStore.highwaysList.find(highway => highway.highway_id === highway_id);

        let highwayName = '';
        if (highway) {
            highwayName = highway.name;
        }

        return (
            <Card fluid color="blue">
                {!editing && (
                    <Fragment>
                        <Card.Content>
                            <span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />
                        </Card.Content>
                        <Card.Content>
                            {highway && (
                                <Fragment>
                                    <Header size="tiny">Шоссе</Header>
                                    <Grid columns={1}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                {highwayName} шоссе, {distanceFromMKAD} км от МКАД
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Fragment>
                            )}
                            <Header size="tiny">Участок</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Коттеджный поселок</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={settlementName} />
                                    </Grid.Column>
                                    <Grid.Column>Площадь</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={`${landArea || '-'} ${printLandAreaUnitType(landArea, landAreaUnitType)}`}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>Категория земли</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.LAND_AREA_STATUS_TYPE_FIND(landStatus)} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Header size="tiny">Инфраструктура на участке</Header>
                            <Grid columns={3}>
                                <Grid.Row>
                                    {LAND_INFRASTURCTURE_FIELDS.map(
                                        field => item[field[0]] && <Grid.Column key={field[0]}>● {field[1]}</Grid.Column>
                                    )}
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                    </Fragment>
                )}
                {editing && (
                    <Card.Content>
                        <LandBlockEditing item_id={item_id} editing={editing} {...item} />
                        <DescriptionBlockEditing editing item_id={item_id} description={item.description} />
                    </Card.Content>
                )}
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, LandBlockShow);
