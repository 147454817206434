import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';

import wishStore from '~/stores/wishStore';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { WishPreviewItem } from '~/components/Items/Wish/WishPreview';

import LoadingItemPreviewContent from '../../../Items/Common/ItemPreview/LoadingItemPreviewContent';

@observer
export class WishPreviewMap extends Component<{ wish_id: number }> {
    constructor(props: { wish_id: number }) {
        super(props);
        wishStore.fetchItem(props.wish_id);
    }

    handleMouseEnter = () => {
        wishStore.highlightActiveEstateIdOnMap(this.props.wish_id);
    };

    handleMouseLeave = () => {
        wishStore.disableEstateHighlight();
    };

    render() {
        const { wish_id } = this.props;

        const { loadingItem } = wishStore.getItem(wish_id);
        if (loadingItem) {
            return (
                <Card>
                    <CardContent>
                        <LoadingItemPreviewContent />
                    </CardContent>
                </Card>
            );
        }

        return (
            <WishPreviewItem
                item_id={wish_id}
                handleClose={() => {}}
                onImgLoad={() => {}}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                trigger={<span>A</span>}
                base="crm"
            />
        );
    }
}

const WishMapList = () => {
    const { selectedMapIds } = wishStore;

    return (
        <>
            {selectedMapIds.map(wish_id => (
                <WishPreviewMap key={wish_id} wish_id={wish_id} />
            ))}
        </>
    );
};

export default observer(WishMapList);
