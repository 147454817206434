import estateL10n from '~/L10n/estate.L10n';

import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';

import { Checkbox, Grid, Header, Divider } from 'semantic-ui-react';

import {
    Estate,
    ESTATE_PROPERTY_TYPE_CAR_PLACEMENT,
    ESTATE_PROPERTY_TYPE_GARAGE,
    ESTATE_PROPERTY_TYPE_GARAGE_BOX
} from '~/types/estate.types';

import estateStore from '~/stores/estateStore';

import EditField from '~/components/Base/EditField';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '~/components/Items/HandlerEditingBlockWrapper';

type GarageBlockEditingProps = HandlerEditingWrappedProps & {
    propertyType?: number;
    garageType: number | null;
    materialType: number | null;
    garageStatus: number | null;
    totalArea: number | null;

    hasLight: boolean | null;
    hasElectricity: boolean | null;
    hasWater: boolean | null;
    hasHeating: boolean | null;
    hasExtinguishingSystem: boolean | null;

    buildingName: string | null;
    parkingType: number | null;

    hasCarWash: boolean | null;
    hasCarService: boolean | null;
    hasTire: boolean | null;
    hasInspectionPit: boolean | null;
    hasVideoSurveillance: boolean | null;
    hasHourSecurity: boolean | null;
    hasAutomaticGates: boolean | null;
    hasEntryByPass: boolean | null;
    hasBasement: boolean | null;
};

export const GARAGE_INFRASTURCTURE_FIELDS: [keyof Estate, string][] = [
    ['hasLight', 'свет'],
    ['hasElectricity', 'электричество'],
    ['hasHeating', 'отопление'],
    ['hasWater', 'водоснабжение'],
    ['hasExtinguishingSystem', 'система пожаротушения']
];

export const CAR_PLACEMENT_INFRASTURCTURE_FIELDS: [keyof Estate, string][] = [
    ['hasCarWash', 'автомойка'],
    ['hasCarService', 'автосервис'],
    ['hasTire', 'шиномонтаж'],
    ['hasInspectionPit', 'смотровая яма'],
    ['hasVideoSurveillance', 'видеонаблюдение'],
    ['hasHourSecurity', 'круглосуточная охрана'],
    ['hasAutomaticGates', 'автоматические ворота'],
    ['hasEntryByPass', 'въезд по пропускам'],
    ['hasBasement', 'подвал/погреб']
];

@observer
class GarageBlockEditing extends Component<GarageBlockEditingProps> {
    constructor(props: GarageBlockEditingProps) {
        super(props);

        const { item_id } = props;

        estateStore.setEditingItem(item_id, {
            propertyType: props.propertyType || ESTATE_PROPERTY_TYPE_GARAGE,
            garageType: props.garageType || null,
            materialType: props.materialType || null,
            garageStatus: props.garageStatus || null,
            totalArea: props.totalArea || null,
            hasLight: props.hasLight || null,
            hasElectricity: props.hasElectricity || null,
            hasWater: props.hasWater || null,
            hasHeating: props.hasHeating || null,
            hasExtinguishingSystem: props.hasExtinguishingSystem || null,
            buildingName: props.buildingName || null,
            parkingType: props.parkingType || null,
            hasCarWash: props.hasCarWash || null,
            hasCarService: props.hasCarService || null,
            hasTire: props.hasTire || null,
            hasInspectionPit: props.hasInspectionPit || null,
            hasVideoSurveillance: props.hasVideoSurveillance || null,
            hasHourSecurity: props.hasHourSecurity || null,
            hasAutomaticGates: props.hasAutomaticGates || null,
            hasEntryByPass: props.hasEntryByPass || null,
            hasBasement: props.hasBasement || null
        });
    }

    render() {
        const { handleChange, handleCheckbox, handleTextAreaChange, item_id } = this.props;

        const { editingItem } = estateStore.getItem(item_id);
        const { garageType, materialType, garageStatus, propertyType, totalArea, buildingName, parkingType } = editingItem;

        return (
            <div className="crm-Item__editingMode">
                {propertyType === ESTATE_PROPERTY_TYPE_GARAGE && (
                    <EditField.Drop
                        onChange={handleChange}
                        label="Тип гаража"
                        name="garageType"
                        value={garageType}
                        options={estateL10n.GARAGE_TYPE_DROPDOWN}
                        clearable
                    />
                )}

                {propertyType === ESTATE_PROPERTY_TYPE_GARAGE_BOX && (
                    <EditField.Drop
                        onChange={handleChange}
                        label="Тип бокса"
                        name="materialType"
                        value={materialType}
                        options={estateL10n.GARAGE_BOX_MATERIAL_TYPE_DROPDOWN}
                        clearable
                    />
                )}

                <EditField.Drop
                    onChange={handleChange}
                    label="Статус"
                    name="garageStatus"
                    value={garageStatus}
                    options={estateL10n.GARAGE_STATUS_DROPDOWN}
                    clearable
                />

                <EditField.InputNumber
                    label="Общая площадь"
                    onChange={handleChange}
                    min={1}
                    max={999999}
                    value={totalArea}
                    name="totalArea"
                    step={0.1}
                    extraLabel="м²"
                    required
                />

                <Grid columns={3}>
                    {GARAGE_INFRASTURCTURE_FIELDS.map(field => (
                        <Grid.Column key={field[0]}>
                            <Checkbox
                                checked={Boolean(editingItem[field[0]])}
                                name={field[0]}
                                label={<label>{field[1]}</label>}
                                onChange={handleCheckbox}
                            />
                        </Grid.Column>
                    ))}
                </Grid>

                {propertyType === ESTATE_PROPERTY_TYPE_CAR_PLACEMENT && (
                    <Fragment>
                        <Divider />
                        <Header as="h3">Здание</Header>

                        <EditField.Text
                            label="Название"
                            placeholder="Название"
                            value={buildingName}
                            name="buildingName"
                            onChange={handleChange}
                        />

                        <EditField.Drop
                            onChange={handleChange}
                            label="Тип парковки"
                            name="parkingType"
                            value={parkingType}
                            options={estateL10n.OFFICE_PARKING_TYPE_DROPDOWN}
                            clearable
                        />

                        <Grid columns={3}>
                            {CAR_PLACEMENT_INFRASTURCTURE_FIELDS.map(field => (
                                <Grid.Column key={field[0]}>
                                    <Checkbox
                                        checked={Boolean(editingItem[field[0]])}
                                        name={field[0]}
                                        label={<label>{field[1]}</label>}
                                        onChange={handleCheckbox}
                                    />
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, GarageBlockEditing);
